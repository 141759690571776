<div style="height: 700px">
  <div class="loader-container">
    <div>
      <div class="mb-6"><img src="/images/loaderwebsite.png" alt="" /></div>
      <div class="loader">
        <div class="inner-loader"></div>
      </div>
    </div>
  </div>
</div>
