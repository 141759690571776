import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      observe: 'response',
      Authorization: '',
    };
    return new HttpHeaders(headersConfig);
  }

  private formatErrors(err: any) {
    return throwError(() => new Error(JSON.stringify(err.error.message)));
  }

  // Get Method
  public get(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    return this.http
      .get(`${path}`, {
        headers: this.setHeaders(),
        params: params,
      })
      .pipe(catchError(this.formatErrors))
      .pipe(map((res: any) => res));
  }

  // Post Method
  public post(path: string, body: any): Observable<any> {
    return this.http.post(`${path}`, body).pipe(
      catchError(this.formatErrors),
      map((res: any) => res)
    );
  }

  // Put Method
  public put(path: string, body: any): Observable<any> {
    return this.http
      .put(`${path}`, body)
      .pipe(catchError(this.formatErrors))
      .pipe(map((res: any) => res));
  }

  // Delete Method
  public delete(path: string): Observable<any> {
    return this.http
      .delete(`${path}`)
      .pipe(catchError(this.formatErrors))
      .pipe(map((res: any) => res));
  }
}
