<button *ngIf="variant === 'default'" [color]="color" [disableRipple]="disableRipple" [disabled]="disable"
  [class]="size + ' ' + className" [id]="id" mat-button [type]="type" (click)="onClick()"><mat-icon *ngIf="withIcon"
    aria-hidden="false" aria-label="Example home icon" [fontIcon]="icon"></mat-icon>
  {{ label }}
</button>
<button *ngIf="variant === 'raised'" [disabled]="disable" [color]="color" [disableRipple]="disableRipple"
  mat-raised-button [class]="size + ' ' + className" [id]="id" [type]="type" (click)="onClick()"><mat-icon
    *ngIf="withIcon" aria-hidden="false" aria-label="Example home icon" [fontIcon]="icon"></mat-icon>
  {{ label }}
</button>
<button *ngIf="variant === 'flat'" mat-flat-button [color]="color" [disableRipple]="disableRipple" [disabled]="disable"
  [class]="size + ' '  + color + ' ' + className" [id]="id" [type]="type" (click)="onClick()"><mat-icon *ngIf="withIcon"
    aria-hidden="false" aria-label="Example home icon" [fontIcon]="icon"></mat-icon>
  {{ label }}
</button>
<button *ngIf="variant === 'stroked'" [disabled]="disable" [color]="color" [disableRipple]="disableRipple"
  [class]="size + ' ' + className" [id]="id" mat-stroked-button [type]="type" (click)="onClick()">
  <mat-icon *ngIf="withIcon" aria-hidden="false" aria-label="Example home icon" [fontIcon]="icon"></mat-icon>
  {{ label }}
</button>
<button *ngIf="variant === 'icon'" [disabled]="disable" [color]="color" [disableRipple]="disableRipple"
  [class]="size + ' ' + className" [id]="id" mat-icon-button disabled
  aria-label="Example icon button with a open in new tab icon" [type]="type" (click)="onClick()">
  <mat-icon>open_in_new</mat-icon>
</button>
<button *ngIf="variant === 'fab'" [disabled]="disable" [color]="color" [disableRipple]="disableRipple"
  [class]="size + ' ' + className" [id]="id" mat-fab aria-label="Example icon button with a bookmark icon" [type]="type"
  (click)="onClick()">
  <mat-icon>bookmark</mat-icon>
</button>
<button *ngIf="variant === 'mini-fab'" [disabled]="disable" [color]="color" [disableRipple]="disableRipple"
  [class]="size + ' ' + className" [id]="id" aria-label="Example icon button with a home icon" mat-mini-fab
  [type]="type" (click)="onClick()">
  <mat-icon *ngIf="withIcon" [fontIcon]="icon"></mat-icon>
</button>