import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private accountIdSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  private projectIdSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  private invoiceItemsIdSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  private coreTeamIdSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  public projectSubject: EventEmitter<any> = new EventEmitter<any>();
  public fixedCostSubject: EventEmitter<any> = new EventEmitter<any>();
  public projectRSubject: EventEmitter<any> = new EventEmitter<any>();
  private projectTypeSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  public isLoading = new BehaviorSubject<boolean>(false);
  public isDarkMode = new BehaviorSubject<boolean>(false);
  public invoiceId: string = '';
  private currencyValue: string | undefined;

  constructor(private router: Router) {}

  componentDestroyed(component: any) {
    const oldNgOnDestroy = component.ngOnDestroy;
    const destroyed$ = new ReplaySubject<void>(1);
    component.ngOnDestroy = () => {
      oldNgOnDestroy.apply(component);
      destroyed$.next(undefined);
      destroyed$.complete();
    };
    return destroyed$;
  }

  setProjectType(type: string): void {
    this.projectTypeSubject.next(type);
  }

  getProjectType(): Observable<string> {
    return this.projectTypeSubject.asObservable();
  }

  setFixedCost(fixedCPst: string) {
    this.fixedCostSubject.next(fixedCPst);
  }

  getFixedCost(): Observable<string> {
    return this.fixedCostSubject.asObservable();
  }

  setProjectId(projectId: string) {
    this.projectIdSubject.next(projectId);
  }

  getProjectId(): Observable<string> {
    return this.projectIdSubject.asObservable();
  }

  setCurrency(currency: string | undefined): void {
    this.currencyValue = currency;
  }

  isUserAuthenticated(): boolean {
    const session = window.localStorage.getItem('session');
    return session !== null;
  }

  getCurrency(): string | undefined {
    return this.currencyValue;
  }

  setAccountId(accountId: string) {
    this.accountIdSubject.next(accountId);
  }

  getAccountId(): Observable<string> {
    return this.accountIdSubject.asObservable();
  }

  setRProjectId(projectId: string) {
    this.projectRSubject.emit(projectId);
  }

  getRProjectId(): string {
    return this.projectIdSubject.value || '';
  }

  setInvoiceId(id: string): void {
    this.invoiceId = id;
  }

  getInvoiceId(): string {
    return this.invoiceId;
  }

  setInvoiceItemId(invoiceItemId: string) {
    this.invoiceItemsIdSubject.next(invoiceItemId);
  }

  getInvoiceItemId(): Observable<string> {
    return this.invoiceItemsIdSubject.asObservable();
  }

  setCoreTeamId(coreTeamId: string) {
    this.coreTeamIdSubject.next(coreTeamId);
  }

  getCoreTeamId(): Observable<string> {
    return this.coreTeamIdSubject.asObservable();
  }

  checkTokenAndRedirect(): void {
    const token = localStorage.getItem('session');
    if (!token) {
      this.router.navigate(['/sign-in']);
      return;
    }

    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) {
      this.router.navigate(['/sign-in']);
      return;
    }

    const payload = JSON.parse(atob(tokenParts[1]));
    const currentTime = Math.floor(Date.now() / 1000);
    if (payload.exp < currentTime) {
      this.router.navigate(['/sign-in']);
    }
  }
}
