import { Component, ViewEncapsulation, Input, EventEmitter, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'sft-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent {

  /**
   * HTML Aria label.
   */
  @Input() ariaLabel?: string;

  /**
   * HTML Aria labbeled by.
   */
  @Input() ariaLabelledBy?: string;

  @Input() label: string = 'button';

  @Input() color: ThemePalette = 'primary';

  @Input() variant: any = 'default';

  @Input() disable = false;

  @Input() withIcon = false;

  @Input() icon: string = 'home';

  @Input() disableRipple = false;

  @Input() size: string = 'medium';

  @Input() type: string = '';

  @Input() className: string = '';

  @Input() id: string = '';

  /**
   * clickfuntion().
   */
  @Output() buttonClick = new EventEmitter<void>();

  onClick(): void {
    this.buttonClick.emit();
  }
}
